import React, { useState, useEffect } from 'react';
import styles from './styles.scss';

const checkCookiesAccepted = () => {
  const cookiesAcceptedTimestamp = window.localStorage.getItem('cookiesPolicyAccepted');
  if (!cookiesAcceptedTimestamp) {
    return false;
  }
  const now = Date.now();
  const timeDifference = now - parseInt(cookiesAcceptedTimestamp, 10);
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysPassed > 30) {
    window.localStorage.removeItem('cookiesPolicyAccepted');
    return false;
  }
  return true;
};

const CookieBanner = ({ privacyPath, primaryColorBackgroundClass, secondaryColorBackgroundHoverClass, companyName = 'This' }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(() => checkCookiesAccepted());

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setCookiesAccepted(checkCookiesAccepted());
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const acceptCookiesHandler = () => {
    window.localStorage.setItem('cookiesPolicyAccepted', Date.now().toString());
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) {
    return null;
  }

  const cookieBannerTextContent = [
    ' uses cookies to enhance your experience.',
    'We use these for advertising and analytics purposes.',
    'By continuing to use this site, you agree to our',
  ].join(' ');

  return (
    <div className={styles.cookieBanner}>
      <p>
        <strong>{companyName}</strong>
        {cookieBannerTextContent}
        <a href={privacyPath} className={styles.privacyLink}>
          privacy policy
        </a>
        .
      </p>
        <button className={`${styles.acceptButton} ${primaryColorBackgroundClass} ${secondaryColorBackgroundHoverClass}`} onClick={acceptCookiesHandler} type="button">
          ACCEPT
        </button>
    </div>
  );
};

export default CookieBanner;
