import { query } from 'utils/graphql';

// Actions
const REQUEST = 'voyager/contact/REQUEST';
const REQUEST_SUCCESS = 'voyager/contact/REQUEST_SUCCESS';
const REQUEST_FAIL = 'voyager/contact/REQUEST_FAIL';

// Reducer
const initialState = {
  requesting: false,
  requested: false,
  showSuccessMessage: false,
  errorMessage: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        requesting: true,
        requested: true,
        showSuccessMessage: false,
        errorMessage: '',
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
        showSuccessMessage: true,
        formType: action.formType,
      };
    case REQUEST_FAIL:
      return {
        ...state,
        requesting: false,
        showSuccessMessage: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    default:
      return state;
  }
}

// Action Creators
export function request(facilityId, note, tenant, curious = false, formType = '') {
  return {
    types: [REQUEST, REQUEST_SUCCESS, REQUEST_FAIL],
    curious,
    formType,
    force: true,
    promise: query`
      mutation RequestContact(
        ${{ facilityId }}: String!
        ${{ note }}: String!
        ${{ tenant }}: LeadTenantInput!
        ${{ curious }}: Boolean
      ) {
        tenant: requestContact(facilityId: $facilityId, note: $note, tenant: $tenant, curious: $curious) {
          id
          firstName
          lastName
        }
      }
    `,
  };
}

export const requesting = state => state.contact.requesting;

export const requested = state => state.contact.requested;

export const showSuccessMessage = state => state.contact.showSuccessMessage;

export const errorMessage = state => state.contact.errorMessage;

export const formType = state => state.contact.formType;
