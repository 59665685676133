/**
 * General Flippers
 */

// Allows Voyager to be used with Channel Promos.
export const FLIPPER_CHANNEL_BASED_PROMOS = 'flipper_voyager_channel_based_promos';

// Shows filters, search, and checkbox column on list view.
export const FLIPPER_ADMIN_LIST_VIEW = 'flipper_voyager_builder_list_view_feature';

// Shows the language column on list view + other internationalization features.
export const FLIPPER_INTERNATIONALIZATION = 'flipper_voyager_internationalization';

// Shows section and widget moving feature.
export const FLIPPER_SECTION_WIDGET_MOVING = 'flipper_voyager_section_widget_moving';

// Shows enterprise widgets and sections.
export const FLIPPER_ENTERPRISE_OPTIONS = 'flipper_voyager_access_enterprise_options';

// Shows cookie policy banner
export const FLIPPER_POLICY_BANNER = 'flipper_voyager_policy_banner';

// Enables the full story data collection in site admin
export const FLIPPER_FULL_STORY = 'flipper_voyager_full_story';

// Enables ability to customize favicon
export const FLIPPER_FAVICON = 'flipper_voyager_custom_favicon';

// Enables custom headers/footers per page type per locale
export const FLIPPER_VOYAGER_HEADER_FOOTER_SETS = 'flipper_voyager_header_footer_sets';

// Enables cateogry for Rental Center widgets
export const FLIPPER_RENTAL_CENTER_OPTIONS = 'flipper_voyager_access_rental_center_options';

// Enables the welcome modal in admin for new users
export const FLIPPER_VOYAGER_WELCOME_MODAL = 'flipper_voyager_welcome_modal';

// Enables the "Domains and SSL Certificates" settings tab in admin settings
export const FLIPPER_VOYAGER_ADMIN_DOMAIN_SSL_SETTINGS = 'flipper_voyager_admin_domain_ssl_settings';

// Enables notification emails to the primaryContact of the FMS company
export const FLIPPER_VOYAGER_SEND_LEAD_NOTIFICATIONS = 'flipper_voyager_send_lead_notifications';

// Exclude list to disable lead notification emails
export const FLIPPER_VOYAGER_EXCLUDE_FROM_NOTIFICATIONS = 'flipper_voyager_exclude_from_lead_notifications';

// Enables the two button short form for reservation creation
export const FLIPPER_VOYAGER_RENTAL_SHORT_FORM = 'flipper_voyager_rental_short_form';
