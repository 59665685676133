import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import CurrentPageContext from '../../../contexts/CurrentPageContext';
import { DELINEATOR } from '../../../../modules/theme/breadcrumbs';
import * as classes from '../../../../modules/theme/classes';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  currentSite: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    tertiaryColor: PropTypes.string.isRequired,
    publicLogo: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    theme: PropTypes.shape({
      breadcrumbs: PropTypes.shape({
        separator: PropTypes.string,
      }),
      fonts: PropTypes.shape({
        primary: PropTypes.shape({
          name: PropTypes.string,
        }).isRequired,
        secondary: PropTypes.shape({
          name: PropTypes.string,
        }).isRequired,
      }),
      map: PropTypes.shape({
        pins: PropTypes.shape({
          primary: PropTypes.shape({
            data: PropTypes.string,
          }),
          secondary: PropTypes.shape({
            color: PropTypes.string,
            data: PropTypes.string,
            label: PropTypes.shape({
              x: PropTypes.number.isRequired,
              y: PropTypes.number.isRequired,
            }),
            type: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  facility: PropTypes.shape({
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    tertiaryColor: PropTypes.string,
    publicLogo: PropTypes.shape({
      alt: PropTypes.string,
      link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
      }),
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    socialMediaPlatforms: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        enabled: PropTypes.string.isRequired,
      }),
    ),
  }),
};

const defaultProps = {
  facility: undefined,
};

const CurrentPageClientProvider = ({
  children,
  currentSite: {
    primaryColor: currentSitePrimaryColor,
    secondaryColor: currentSiteSecondaryColor,
    tertiaryColor: currentSiteTertiaryColor,
    publicLogo: currentSitePublicLogo,
    socialMedia: currentSiteSocialMediaPlatforms,
    theme: {
      breadcrumbsDelineator,
      fonts: {
        primary: {
          name: primaryFontFamily,
        },
        secondary: {
          name: secondaryFontFamily,
        },
      },
      map: {
        pins: {
          primary: {
            data: primaryMapPinData,
          },
          secondary: {
            data: secondaryMapPinData,
            color: secondaryMapPinColor,
            type: secondaryMapPinType,
            label: secondaryMapPinLabel,
          },
        },
      },
    },
  },
  facility,
}) => {
  const {
    primaryColor: facilityPrimaryColor,
    secondaryColor: facilitySecondaryColor,
    tertiaryColor: facilityTertiaryColor,
    publicLogo: facilityPublicLogo,
    socialMediaPlatforms: facilitySocialMediaPlatforms,
  } = facility || {};

  const primaryColor = facilityPrimaryColor || currentSitePrimaryColor;
  const secondaryColor = facilitySecondaryColor || currentSiteSecondaryColor;
  const tertiaryColor = facilityTertiaryColor || currentSiteTertiaryColor;
  const publicLogo = facilityPublicLogo || currentSitePublicLogo;
  const socialMedia = facilitySocialMediaPlatforms || currentSiteSocialMediaPlatforms;

  const value = {
    primaryColor,
    secondaryColor,
    tertiaryColor,
    publicLogo,
    socialMedia,
    primaryColorBackgroundClass: classes.PRIMARY_COLOR_BACKGROUND_CLASS,
    primaryColorBackgroundHoverClass: classes.PRIMARY_COLOR_BACKGROUND_HOVER_CLASS,
    primaryColorBorderClass: classes.PRIMARY_COLOR_BORDER_CLASS,
    primaryColorBorderHoverClass: classes.PRIMARY_COLOR_BORDER_HOVER_CLASS,
    primaryColorFillClass: classes.PRIMARY_COLOR_FILL_CLASS,
    primaryColorClass: classes.PRIMARY_COLOR_CLASS,
    primaryColorHoverClass: classes.PRIMARY_COLOR_HOVER_CLASS,
    secondaryColorBackgroundClass: classes.SECONDARY_COLOR_BACKGROUND_CLASS,
    secondaryColorBackgroundHoverClass: classes.SECONDARY_COLOR_BACKGROUND_HOVER_CLASS,
    secondaryColorBorderClass: classes.SECONDARY_COLOR_BORDER_CLASS,
    secondaryColorBorderHoverClass: classes.SECONDARY_COLOR_BORDER_HOVER_CLASS,
    secondaryColorFillClass: classes.SECONDARY_COLOR_FILL_CLASS,
    secondaryColorClass: classes.SECONDARY_COLOR_CLASS,
    secondaryColorHoverClass: classes.SECONDARY_COLOR_HOVER_CLASS,
    tertiaryColorBackgroundClass: classes.TERTIARY_COLOR_BACKGROUND_CLASS,
    tertiaryColorBackgroundHoverClass: classes.TERTIARY_COLOR_BACKGROUND_HOVER_CLASS,
    tertiaryColorBorderClass: classes.TERTIARY_COLOR_BORDER_CLASS,
    tertiaryColorBorderHoverClass: classes.TERTIARY_COLOR_BORDER_HOVER_CLASS,
    tertiaryColorFillClass: classes.TERTIARY_COLOR_FILL_CLASS,
    tertiaryColorClass: classes.TERTIARY_COLOR_CLASS,
    tertiaryColorHoverClass: classes.TERTIARY_COLOR_HOVER_CLASS,
    primaryFontFamily,
    primaryFontFamilyClass: primaryFontFamily && classes.PRIMARY_FONT_FAMILY_CLASS,
    secondaryFontFamily,
    secondaryFontFamilyClass: secondaryFontFamily && classes.SECONDARY_FONT_FAMILY_CLASS,
    primaryMapPinData,
    secondaryMapPinColor,
    secondaryMapPinData,
    secondaryMapPinLabel,
    secondaryMapPinType,
    breadcrumbsDelineator: breadcrumbsDelineator || DELINEATOR,
  };

  return (
    <CurrentPageContext.Provider value={value}>
      {children}
    </CurrentPageContext.Provider>
  );
};

CurrentPageClientProvider.propTypes = propTypes;
CurrentPageClientProvider.defaultProps = defaultProps;

// TODO: This is not being used currently and instead data for this component
// is being pulled from the Redux store.
CurrentPageClientProvider.fragments = {
  currentSite: gql`
    fragment CurrentPageProviderCurrentSite on Site {
      primaryColor
      secondaryColor
      tertiaryColor
      publicLogo {
        alt
        link {
          href
          target
        }
        title
        url
      }
      theme {
        fonts {
          primary {
            name
          }
          secondary {
            name
          }
        }
        map {
          pins {
            primary {
              data
            }
            secondary {
              color
              label {
                x
                y
              }
              data
              type
            }
          }
        }
        breadcrumbs {
          separator
        }
      }
    }
  `,
  facility: gql`
    fragment CurrentPageProviderFacility on Facility {
      primaryColor
      secondaryColor
      tertiaryColor
      publicLogo {
        alt
        link {
          href
          target
        }
        title
        url
      }
    }
  `,
};

export default CurrentPageClientProvider;
